<template lang="pug">
include ../pug/svg
div#dev
	h1 Development
	pre paymentIntent: {{paymentIntent}}
	pre userOrder: {{userOrder}}
	pre payload: {{payload}}
	pre orderRef: {{orderRef}}
</template>

<script>
import AccountService from '../services/AccountService';
import PaymentService from '../services/PaymentService';
	
export default {
	name: 'AccountDev',
	components: {
	},
	data() {
		return {
			paymentIntentId: 'pi_3Kfq07GwqU8XFdo203Oinfwp',
			paymentIntent: null,
			userOrder: null,
			payload: null,
			orderRef: null,
		};
	},
	async created() {
		try {
			// retrieves payment intent/customer data
			let response = await AccountService.getOrderPayment(this.paymentIntentId);
			this.paymentIntent = response.data.payment;
			
			// get user order from TRR database
			response = await AccountService.getOrderByIntent(this.paymentIntent.id);
			this.userOrder = response.data.userOrder;
			
			// force order to mintsoft for fulfiment
			this.forcePaymentComplete();
			
		} catch (error) {
			console.log(error.response);
		}
	},
	methods: {
		async forcePaymentComplete() {
			try {
				// ensure fulfilment has not been inititiated
				if (this.userOrder.mintsoftOrderId !== null) {
					console.log('fulfilment already inititiated:', this.userOrder.mintsoftOrderId);
					return;
				}
				
				const payload = {
					id: this.paymentIntent.id,
					user: {
						id: this.userOrder.userId,
						email: this.paymentIntent.customer.email,
						customer: {
							firstName: this.paymentIntent.customer.firstName,
							lastName: this.paymentIntent.customer.lastName,
							businessName: this.paymentIntent.customer.company,
							shipping: {
								name: this.paymentIntent.customer.firstName + ' ' + this.paymentIntent.customer.lastName,
								phone: this.paymentIntent.customer.phone,
								address: {
									line1: this.paymentIntent.customer.address.line1,
									line2: this.paymentIntent.customer.address.line2,
									city: this.paymentIntent.customer.address.city,
									state: this.paymentIntent.customer.address.state,						
									postal_code: this.paymentIntent.customer.address.postal_code,
									country: this.paymentIntent.customer.address.country,
								},
							},
						},
					},				
				};
				
				this.payload = payload;
				
				// manually inititiate fulfilment
				const {data} = await PaymentService.paymentComplete(this.payload);
				this.orderRef = data.orderRef;
				
			} catch (error) {
				console.log(error.response);
			}
		},
	},
};
</script>

<style lang="scss">
#dev {
}
</style>